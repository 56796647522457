<script lang='ts' setup>
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons-vue'
const $router = useRouter()
const JoinUs = [
  {
    name: 'ADVERTISER WITH CEL-ELEMENTS',
    link: '/#',
  },
  {
    name: 'PARTNERSHIP WITH CEL-ELEMENTS',
    link: '/#',
  },
  {
    name: 'WORK WITH CEL-ELEMENTS',
    link: '/#',
  },
]

const ShopWithUs = [
  // {
  //   name: 'CUSTOMER CARE',
  //   link: '/#',
  // },
  // {
  //   name: 'STORE LOCATOR',
  //   link: '/#',
  // },
  {
    name: 'EVENT CALENDER',
    link: '/#',
  },
  {
    name: 'SHIPPING & DELIVERY POLICY',
    link: '/deliveryPolicy',
  },
  {
    name: 'RETURN & REFUND POLICY',
    link: '/returnPolicy',
  },
]

const LearnMore = [
  {
    name: 'WHY CEL-ELEMENTS™',
    link: '/#',
  },
  {
    name: 'LOCATE US',
    link: '/#',
  },
  {
    name: 'HOW TO BUY?',
    link: '/#',
  },
  // {
  //   name: 'CONTACT US',
  //   link: '/contact',
  // },
]

function routeTo(param: string) {
  console.log('click', param)
  window.open(param, '_blank')
}
</script>

<template>
  <div class="footer-wrapper">
    <a-layout-footer class="hidePrint" style="margin-top: 4px; margin-bottom: 5px; background-color: #30421c;">
      <div class="gutter-example">
        <a-row :gutter="[16, 16]">
          <a-col :xs="24" :sm="12" :md="6" :lg="6">
            <RouterLink to="/">
              <img src="/img/CelElements.svg" width="200" height="150" style="margin-left: auto;margin-right: auto;" />
            </RouterLink>
            <div class="social text-center">
              <div style="color:rgb(252, 212, 212);; font-size: 18px; margin: 5px">Follow Us</div>
              <span @click="routeTo('https://www.facebook.com/CelElements/')">
                <FacebookOutlined class="icon" />
              </span>

              <span @click="routeTo('https://www.instagram.com/cel_elements/')">
                <InstagramOutlined class="icon" />
              </span>
              <span>
                <TwitterOutlined class="icon" />
              </span>
              <span>
                <YoutubeOutlined class="icon" />
              </span>
            </div>
          </a-col>

          <!-- <a-col class="gutter-row" :xs="24" :sm="12" :md="6" :lg="6">
            <div class="gutter-box">Join Us</div>
            <template v-for="(a, i) in JoinUs" :key="i">
              <div>
                <router-link :to="a.link" class="url">{{ a.name }}</router-link>
              </div>
              <br />
            </template>
          </a-col> -->

          <a-col class="gutter-row" :xs="24" :sm="12" :md="6" :lg="6">
            <div class="gutter-box">Shop With Us</div>
            <template v-for="(a, u) in ShopWithUs" :key="u">
              <div>
                <router-link :to="a.link" class="url">{{ a.name }}</router-link>
              </div>
              <br />
            </template>
          </a-col>

          <a-col class="gutter-row" :xs="24" :sm="12" :md="6" :lg="6">
            <div class="gutter-box">Learn More</div>
            <template v-for="(a, o) in LearnMore" :key="o">
              <div>
                <router-link :to="a.link" class="url">{{ a.name }}</router-link>
              </div>
              <br />
            </template>
          </a-col>
        </a-row>

        <div style="textAlign: center; margin:10px; padding:20px; color:whitesmoke">
          <div class="">Copyright © 2013 Cel-Elements Holdings (M) Sdn. Bhd. All Rights Reserved.</div>
          <div>
          <router-link class="url" to="/termsOfUse">TERMS OF USE</router-link> |
          <router-link class="url" to="/privacyPolicy">PRIVACY POLICY</router-link>
          </div>
        </div>
        <div>

        </div>
      </div>
    </a-layout-footer>

    <button class="fixed bottom-4 right-4">
      <a href="https://wa.link/25hywh" target="_blank" rel="noopener noreferrer">
        <img src="/img/WhatsApp.svg.png" width="50" height="50" style="margin-left: auto;margin-right: auto;" />
      </a>
    </button>
  </div>
</template>

<style scoped>
.ant-layout-footer {
  @apply px-0;
}

.footer-wrapper {
  background: #30421c;
}

.gutter-example {
  background-color: #30421c;
  margin: 10px;
  border: 0;
}
.gutter-row {
  padding-block: 15px;
  margin-block: 10px;
}
.gutter-box {
  /* background: transparent; */
  padding: 10px 0;
  color: rgb(252, 212, 212);
  font-size: 18px;
}
.url {
  color: whitesmoke;
}

.icon {
  font-size: 25px;
  padding: 5px;
  margin: 5px;
  background-color: whitesmoke;
  border-radius: 10px;
}
</style>
